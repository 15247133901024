ion-footer.add-to-cart {
  background: var(--ion-color-mid-grey);
  padding-bottom: env(safe-area-inset-bottom);

  ion-toolbar {
    padding-bottom: 0;
  }

  ion-toolbar {
    --background: var(--ion-color-mid-grey);

    ion-title {
      color: var(--ion-color-text);
      text-align: center;
    }

    ion-buttons ion-button {
      height: 40px !important;
      width: 40px;
      border-radius: 45px;
      margin-top: 10px;
      margin-bottom: 10px;
      background: var(--ion-color-text);
      ion-icon {
        color: var(--ion-color-text-contrast);
      }
    }
  }

  .add_to_order {
    ion-button {
      --border-radius: 3px;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 14px;
      height: 60px;
      font-weight: bold;
    }
  }
}
