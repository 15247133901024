body.theme-2 {
  --ion-color-dark-purple: #00a384;
  --ion-color-dark-purple-rgb: 0, 163, 132;
  --ion-color-dark-purple-contrast: #ffffff;
  --ion-color-dark-purple-contrast-rgb: 255, 255, 255;
  --ion-color-dark-purple-shade: #008f74;
  --ion-color-dark-purple-tint: #1aac90;

  --ion-color-mid-purple: #00a384;
  --ion-color-mid-purple-rgb: 0, 163, 132;
  --ion-color-mid-purple-contrast: #ffffff;
  --ion-color-mid-purple-contrast-rgb: 255, 255, 255;
  --ion-color-mid-purple-shade: #008f74;
  --ion-color-mid-purple-tint: #1aac90;

  --ion-color-purple: #ffffff;
  --ion-color-purple-rgb: 255, 255, 255;
  --ion-color-purple-contrast: #000000;
  --ion-color-purple-contrast-rgb: 0, 0, 0;
  --ion-color-purple-shade: #e0e0e0;
  --ion-color-purple-tint: #ffffff;

  --ion-color-pink: #fb4f41;
  --ion-color-pink-rgb: 251, 79, 65;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 0, 0, 0;
  --ion-color-pink-shade: #dd4639;
  --ion-color-pink-tint: #fb6154;

  --ion-color-dark-grey: #dbe3eb;
  --ion-color-dark-grey-rgb: 219, 227, 235;
  --ion-color-dark-grey-contrast: #000000;
  --ion-color-dark-grey-contrast-rgb: 0, 0, 0;
  --ion-color-dark-grey-shade: #c1c8cf;
  --ion-color-dark-grey-tint: #dfe6ed;

  --ion-color-mid-grey: #ffffff;
  --ion-color-mid-grey-rgb: 255, 255, 255;
  --ion-color-mid-grey-contrast: #000000;
  --ion-color-mid-grey-contrast-rgb: 0, 0, 0;
  --ion-color-mid-grey-shade: #e0e0e0;
  --ion-color-mid-grey-tint: #ffffff;

  --ion-color-light-grey: #00a384;
  --ion-color-light-grey-rgb: 0, 163, 132;
  --ion-color-light-grey-contrast: #ffffff;
  --ion-color-light-grey-contrast-rgb: 255, 255, 255;
  --ion-color-light-grey-shade: #008f74;
  --ion-color-light-grey-tint: #1aac90;

  --ion-color-text: #232e43;
  --ion-color-text-rgb: 35, 46, 67;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #1f283b;
  --ion-color-text-tint: #394356;

  --ion-color-text: #232e43;
  --ion-color-text-rgb: 35, 46, 67;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #1f283b;
  --ion-color-text-tint: #394356;

  --ion-color-primary: var(--ion-color-purple);
  --ion-color-primary-rgb: var(--ion-color-purple-rgb);
  --ion-color-primary-contrast: var(--ion-color-purple-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-purple-shade);
  --ion-color-primary-tint: var(--ion-color-purple-tint);

  --ion-text-color: var(--ion-color-text);
  --ion-color-contrast: var(--ion-color-dark-grey);

  .alert-wrapper {
    --ion-text-color: var(---ion-color-text);
    --ion-color-primary: var(--ion-color-dark-purple);
  }
}
