.menu-padding {
  padding-left: calc(var(--ion-padding) / 2);
  padding-right: calc(var(--ion-padding) / 2);
}

ion-list.menu {
  & {
    --padding-start: calc(var(--ion-padding) / 2);
    --padding-end: calc(var(--ion-padding) / 2);
    --ion-item-background: var(--ion-color-dark-grey);
  }

  ion-item-divider {
    --background: var(--ion-color-dark-grey);
    --padding-start: calc(var(--ion-padding) / 2);
  }
  ion-item-divider.ios[sticky] {
    // top: 60px;
  }
  ion-item-divider.md[sticky] {
    // top: 58px;
  }

  // Searchbar
  ion-item-divider.searchbar {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0px;
    top: 0 !important;
    z-index: 101;
  }
  ion-item-divider.searchbar.md {
    border-bottom-color: var(--ion-color-mid-grey);
  }

  ion-searchbar {
    --color: var(--ion-text-color);
    --background: var(--ion-color-mid-grey);
    padding-inline-start: calc(var(--ion-padding) / 2);
    padding-inline-end: calc(var(--ion-padding) / 2);
  }

  // Menu Items
  ion-item {
    & {
      margin-top: calc(var(--ion-padding) / 3);
      margin-bottom: calc(var(--ion-padding) / 3);
      margin-left: calc(var(--ion-padding) / 2);
      margin-right: calc(var(--ion-padding) / 2);
      --ion-item-background: var(--ion-color-mid-grey);
      --inner-padding-end: 0;
      --padding-end: 0;
      display: flex;
    }

    ion-label {
      white-space: normal !important;
      line-height: 1.5;
    }

    // The price
    .price {
      font-weight: bold;
      color: inherit;
      font-size: inherit;
    }

    // The start image
    div[slot="start"] {
      & {
        // by default hide the thumbanil. It is only shown if show-thumbnail class is added
        display: none;
        height: 100%;
        margin-bottom: 0;
        margin-top: 0;
        width: 3.2em;
        background-position: center;
        background-size: cover;
        font-size: inherit;
      }
    }

    // The end button
    ion-button[slot="end"] {
      // margin-inline-start: 0;
      margin-inline-end: 0;
      margin-top: 0;
      margin-bottom: 0;
      --border-radius: 0;
      border-radius: 0;
      align-self: stretch;
      height: 100%;
      width: 3.2em;
      font-size: inherit;
    }
  }

  /**
   * iOS specific fixes
  */

  ion-item.ios,
  ion-item-divider.ios {
    --border-radius: var(--standard-border-radius);
    border-radius: var(--standard-border-radius);
  }

  /**
   * MD Specific fixes
   */
  ion-note.md {
    align-self: center;
  }
}
// Override to display the image when required
ion-list.menu.show-thumbnail {
  div[slot="start"] {
    display: initial;
  }

  ion-item {
    --inner-padding-start: 0;
    --padding-start: 0;
  }
}
