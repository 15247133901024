// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


//import roboto

@import url("https://fonts.googleapis.com/css2?family=Yantramanav&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");


/* NEW COLORS */
:root {
  --ion-default-font: "Yantramanav", sans-serif;
  --ion-font-family: "Yantramanav", sans-serif;

  --standard-border-radius: 12px;

  --ion-color-primary: #e36936;
  --ion-color-primary-rgb: 227, 105, 54;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #c85c30;
  --ion-color-primary-tint: #e6784a;

  --ion-color-secondary: #101010;
  --ion-color-secondary-rgb: 16,16,16;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #282828;
  --ion-color-secondary-tint: #282828;


  --ion-color-tertiary: rgba(255, 255, 255, 0.4);
  --ion-color-tertiary-rgb: 255, 255, 255, 0.4;
  --ion-color-tertiary-contrast: var(--ion-color-secondary-contrast);
  --ion-color-tertiary-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-tertiary-shade: #333333;
  --ion-color-tertiary-tint: rgba(255, 255, 255, 0.3);;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #000000;
  --ion-color-medium-rgb: 0, 0, 0;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #000000;
  --ion-color-medium-tint: #101010;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #101010;

  --ion-color-light: #fef9ef;
  --ion-color-light-rgb: 254, 249, 239;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0dbd2;
  --ion-color-light-tint: #fefaf1;

  --ion-color-text: #ffffff;
  --ion-color-text-rgb: 255, 255, 255;
  --ion-color-text-contrast: #000000;
  --ion-color-text-contrast-rgb: 0, 0, 0;
  --ion-color-text-shade: #e0e0e0;
  --ion-color-text-tint: #ffffff;

  --ion-padding: 16px;

  --ion-text-color: var(--ion-color-text);
  --ion-text-color-rgb: var(--ion-color-text-rgb);
  --ion-color-contrast: var(--ion-color-dark-grey);
  --ion-background-color: var(--ion-color-medium);
  --ion-background-color-tint: var(--ion-color-medium-tint);
  --ion-overlay-background-color: var(--ion-color-white-shade);



	--ion-color-tag-system: #EB4444;
	--ion-color-tag-system-rgb: 235,68,68;
	--ion-color-tag-system-contrast: #ffffff;
	--ion-color-tag-system-contrast-rgb: 255,255,255;
	--ion-color-tag-system-shade: #cf3c3c;
	--ion-color-tag-system-tint: #ed5757;

  
  --ion-color-tag-highlight: #49DCB1;
	--ion-color-tag-highlight-rgb: 73,220,177;
	--ion-color-tag-highlight-contrast: #ffffff;
	--ion-color-tag-highlight-contrast-rgb: 0,0,0;
	--ion-color-tag-highlight-shade: #40c29c;
	--ion-color-tag-highlight-tint: #5be0b9;

  --ion-color-tag-general: #E36936;
	--ion-color-tag-general-rgb: 227,105,54;
	--ion-color-tag-general-contrast: #000000;
	--ion-color-tag-general-contrast-rgb: 0,0,0;
	--ion-color-tag-general-shade: #c85c30;
	--ion-color-tag-general-tint: #e6784a;

  --ion-color-tag-taste: #FF9FE5;
	--ion-color-tag-taste-rgb: 255,159,229;
	--ion-color-tag-taste-contrast: #000000;
	--ion-color-tag-taste-contrast-rgb: 0,0,0;
	--ion-color-tag-taste-shade: #e08cca;
	--ion-color-tag-taste-tint: #ffa9e8;

  --ion-color-tag-music: #337CA0;
	--ion-color-tag-music-rgb: 51,124,160;
	--ion-color-tag-music-contrast: #ffffff;
	--ion-color-tag-music-contrast-rgb: 255,255,255;
	--ion-color-tag-music-shade: #2d6d8d;
	--ion-color-tag-music-tint: #4789aa;

  --ion-color-tag-brand: #FFC857;
	--ion-color-tag-brand-rgb: 255,200,87;
	--ion-color-tag-brand-contrast: #000000;
	--ion-color-tag-brand-contrast-rgb: 0,0,0;
	--ion-color-tag-brand-shade: #e0b04d;
	--ion-color-tag-brand-tint: #ffce68;
}


























.ion-color-tag-brand {
	--ion-color-base: var(--ion-color-tag-brand);
	--ion-color-base-rgb: var(--ion-color-tag-brand-rgb);
	--ion-color-contrast: var(--ion-color-tag-brand-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tag-brand-contrast-rgb);
	--ion-color-shade: var(--ion-color-tag-brand-shade);
	--ion-color-tint: var(--ion-color-tag-brand-tint);
}

.ion-color-tag-music {
	--ion-color-base: var(--ion-color-tag-music);
	--ion-color-base-rgb: var(--ion-color-tag-music-rgb);
	--ion-color-contrast: var(--ion-color-tag-music-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tag-music-contrast-rgb);
	--ion-color-shade: var(--ion-color-tag-music-shade);
	--ion-color-tint: var(--ion-color-tag-music-tint);
}

.ion-color-tag-taste {
	--ion-color-base: var(--ion-color-tag-taste);
	--ion-color-base-rgb: var(--ion-color-tag-taste-rgb);
	--ion-color-contrast: var(--ion-color-tag-taste-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tag-taste-contrast-rgb);
	--ion-color-shade: var(--ion-color-tag-taste-shade);
	--ion-color-tint: var(--ion-color-tag-taste-tint);
}

.ion-color-tag-general {
	--ion-color-base: var(--ion-color-tag-general);
	--ion-color-base-rgb: var(--ion-color-tag-general-rgb);
	--ion-color-contrast: var(--ion-color-tag-general-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tag-general-contrast-rgb);
	--ion-color-shade: var(--ion-color-tag-general-shade);
	--ion-color-tint: var(--ion-color-tag-general-tint);
}

.ion-color-tag-system {
	--ion-color-base: var(--ion-color-tag-system);
	--ion-color-base-rgb: var(--ion-color-tag-system-rgb);
	--ion-color-contrast: var(--ion-color-tag-system-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tag-system-contrast-rgb);
	--ion-color-shade: var(--ion-color-tag-system-shade);
	--ion-color-tint: var(--ion-color-tag-system-tint);
}

.ion-color-tag-highlight {
	--ion-color-base: var(--ion-color-tag-highlight);
	--ion-color-base-rgb: var(--ion-color-tag-highlight-rgb);
	--ion-color-contrast: var(--ion-color-tag-highlight-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tag-highlight-contrast-rgb);
	--ion-color-shade: var(--ion-color-tag-highlight-shade);
	--ion-color-tint: var(--ion-color-tag-highlight-tint);
}



.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(---ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.dq-bg-color-dark-purple {
  background-color: var(--ion-color-dark-purple);
}
.dq-color-dark-purple {
  color: var(--ion-color-dark-purple);
}
.ion-color-dark-purple {
  --ion-color-base: var(--ion-color-dark-purple);
  --ion-color-base-rgb: var(--ion-color-dark-purple-rgb);
  --ion-color-contrast: var(--ion-color-dark-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-purple-shade);
  --ion-color-tint: var(--ion-color-dark-purple-tint);
}

.dq-bg-color-mid-purple {
  background-color: var(--ion-color-mid-purple);
}
.dq-color-mid-purple {
  color: var(--ion-color-mid-purple);
}



.ion-color-mid-purple {
  --ion-color-base: var(--ion-color-mid-purple);
  --ion-color-base-rgb: var(--ion-color-mid-purple-rgb);
  --ion-color-contrast: var(--ion-color-mid-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid-purple-shade);
  --ion-color-tint: var(--ion-color-mid-purple-tint);
}

.dq-bg-color-purple {
  background-color: var(--ion-color-purple);
}
.dq-color-purple {
  color: var(--ion-color-purple);
}
.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.dq-bg-color-dark-gray {
  background-color: var(--ion-color-dark-grey);
}
.dq-color-dark-gray {
  color: var(--ion-color-dark-grey);
}
.ion-color-dark-grey {
  --ion-color-base: var(--ion-color-dark-grey);
  --ion-color-base-rgb: var(--ion-color-dark-grey-rgb);
  --ion-color-contrast: var(--ion-color-dark-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-grey-shade);
  --ion-color-tint: var(--ion-color-dark-grey-tint);
}

.dq-bg-color-mid-grey {
  background-color: var(--ion-color-mid-grey);
}
.dq-color-mid-grey {
  color: var(--ion-color-mid-grey);
}
.ion-color-mid-grey {
  --ion-color-base: var(--ion-color-mid-grey);
  --ion-color-base-rgb: var(--ion-color-mid-grey-rgb);
  --ion-color-contrast: var(--ion-color-mid-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid-grey-shade);
  --ion-color-tint: var(--ion-color-mid-grey-tint);
}

.dq-bg-color-light-gray {
  background-color: var(--ion-color-light-grey);
}
.dq-color-light-gray {
  color: var(--ion-color-light-grey);
}
.ion-color-light-grey {
  --ion-color-base: var(--ion-color-light-grey);
  --ion-color-base-rgb: var(--ion-color-light-grey-rgb);
  --ion-color-contrast: var(--ion-color-light-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-grey-shade);
  --ion-color-tint: var(--ion-color-light-grey-tint);
}

.ion-color-text {
  --ion-color-base: var(--ion-color-text);
  --ion-color-base-rgb: var(--ion-color-text-rgb);
  --ion-color-contrast: var(--ion-color-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-shade);
  --ion-color-tint: var(--ion-color-text-tint);
}

/*GLOBAL STYLE*/
ion-content {
  --background: var(--ion-color-medium);
}
/*
.alert-radio-label {
  color: #000000 !important;
}
.alert-radio-icon .alert-radio-inner {
  border-color: black !important;
} */
.action-sheet-group {


  background-color:rgba(26, 26, 26, 0.4) !important;
 backdrop-filter: Saturate(180%) blur(28px);
 //change color
---button-color: white !important;
 color: white !important;
 --color: white !important;
 .toast-button-inner{
    color: white !important;
 }
}

.action-sheet-button.sc-ion-action-sheet-ios {
  color: white !important;
}
.action-sheet-button.sc-ion-action-sheet-md {
  color: white !important;
}
ion-header,
ion-footer {
  --ion-toolbar-background: var(--ion-background-color);
  background: var(--ion-background-color);
  ion-toolbar {
    // --background: var(--ion-color-dark-grey);
    --border-width: 0 !important;
    // --ion-toolbar-color: var(--ion-color-text);

    ion-title {
      font-weight: bold;
    }

    ion-back-button span {
      &.button-text {
        font-size: 13px;
        text-transform: uppercase;
      }
    }
  }
}

.open-table-modal .modal-wrapper {
  max-width: 90vw;
  height: 225px;
  ion-header ion-button {
    margin: 0;
    font-size: 30px;
    padding: 0;
    transform: translateY(-3px);
    color: white;
  }
  ion-header ion-toolbar:first-child {
    padding-top: 0;
  }
}
.settings-card {
  border-radius: var(--standard-border-radius);
}

.extrapdd {
  cdk-virtual-scroll-content-wrapper {
    padding-bottom:  calc(var(--ion-safe-area-bottom) + var(--ion-safe-area-top) + 200px);

  }
}

ion-menu-button {
  color: var(--ion-color-text);
}

.cart-list-wrapper {
  ion-item {
    --background: transparent;
    --background-activated: transparent;
  }
}

ion-segment-button.md,
ion-button.md {
  text-transform: none;
}

.alert-wrapper {
  background-color:rgba(26, 26, 26, 0.4) !important;
 backdrop-filter: Saturate(180%) blur(28px);
 //change color---button-color: white !important;
 --ion-text-color: white !important;
 color: white !important;
 --color: white !important;
  
  --ion-text-color: white !important;
  --ion-color-primary: white !important;
}
.alert-button-inner {
  color: white;
  --color: white !important;
}
/*
.alert-wrapper {
  --ion-text-color: var(--ion-color-text-contrast);
}
.alert-wrapper {
  color: white !important;
  background-color: var(--ion-color-primary) !important;
  --background: var(--ion-color-background);
  --ion-text-color: black !important;
  --ion-color-primary: white !important;
}
.alert-button-inner {
  color: black;
  --color: black !important;
}*/

ion-list.dark-list {
  --ion-item-background: var(--ion-background-color);
}

// fix the color of the ion-refresh spinner on android/md design
ion-refresher.md {
  --ion-color-primary: var(--ion-color-text-contrast);
}

// Fix the text color on ios for action sheet buttons (excluding the cancel
// button



ion-toast::part(button) {
  color: white;
  font-size: 1em;
  height: auto;
}
ion-toast::part(header) {
  color: white;
  font-size: 0.8em;
  //medium weight
  font-weight: 600;
  
}

ion-toast::part(icon) {

 
  //make icon 10% bigger
  width: 35px;
  height: 35px;
}





ion-toast {

  --background:rgba(26, 26, 26, 0.4);
 --backdrop-filter: Saturate(180%) blur(28px);
 //change color
 --color: white !important;
 .toast-button-inner{
    color: white !important;
 }

}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}


.interaction-click {

  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.7;
  }
}


/*
ion-toast.small-toast {
 width: 50%;
 height: 40px;
  margin-left: 25%;
  margin-right: 25%;
 
}
ion-toast.small-toast::part(container) {
 border-radius: 30px;
 padding: 0px !important;
 .toast-content {
  padding: 0px !important;
 }
}
ion-toast.small-toast::part(button) {
  //hide
 
  display: none;
}
*/
.loading-wrapper.ion-overlay-wrapper.sc-ion-loading-ios {
  background:rgba(2, 2, 2, 0.4);
  backdrop-filter: Saturate(180%) blur(25px);
  border-radius: var(--standard-border-radius);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  border: 1px solid var(--ion-color-secondary-tint);
}


input.custom-phone-inp {
 
    background: transparent;
    border: none;
    border-radius: var(--standard-border-radius);
    color: var(--ion-color-light);
    font-size: 1em;
    padding: 0.5em;
    width: 100%;
    outline: none !important;
    padding-left: 85px !important;
}

:host(.legacy-checkbox) label {
  .ion-cb-2 {
    display: none !important;
  }
  
}

ion-popover {
  --background: var(--ion-color-secondary);
  ::part(content) {
    border: 1px solid var(--ion-color-secondary-tint);
  }
}
ion-popover::part(content) {
  border: 1px solid var(--ion-color-secondary-tint);
}

  

.dropdown-menu {
background-color: black !important;
border: 1px solid var(--ion-color-secondary-tint) !important;

.iti__country-list {

  background-color: black !important;
  color: white !important;
}

.search-container {
  background-color: black !important;
  color: white !important;
  input {
    background-color: black !important;
    color: white !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    outline: none !important;
  }
}
}

.toast-button-inner{
  color: white !important;
}
ion-toast::part(container) {

  background:rgba(2, 2, 2, 0.4);
 backdrop-filter: Saturate(180%) blur(25px);
 border-radius: var(--standard-border-radius);
 box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
 border: 1px solid var(--ion-color-secondary-tint); //rgba(255, 255, 255, 0.1)
}

.transparentmodal {
  
  --background: transparent;
  .ion-page {
  background:rgba(26, 26, 26, 0.34);
  backdrop-filter: Saturate(180%) blur(30px);
 ion-content::part(background) {
  background: transparent
}
ion-content::part(scroll) {
  background:transparent;
}
  }
  
  
  
}

.rounded-modal {
//round top right and left
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  --border-radius: 30px;

}
//for ionic modals
.dark-backdrop {
  --backdrop-opacity:1;

}


.blurrymodal {
  background:rgba(26, 26, 26, 0.24);
  backdrop-filter: Saturate(180%) blur(40px);
 ion-content::part(background) {
  background: transparent
}

}


ion-modal::part(content) {
  border: 1px solid var(--ion-color-secondary-tint)
}



.ngx-charts .gridline-path {
  stroke: var(--ion-color-secondary-tint) !important;
}

.dangerbutton {
  color: red !important;
  .alert-button-inner {
    color: var(--ion-color-danger, #eb445a) !important;
  }
}

.PaymentModal .modal-wrapper {
  --background: transparent !important;
  background: transparent !important;
}

@media only screen and (min-width: 600px) {
  ion-toast {
    font-size: 1.5em;
  }
}

td.available.active.start-date {
  background-color: var(--ion-color-primary);
  color: white;
}
td.available.active.start-date.in-range {
  background-color: var(--ion-color-primary);
  color: white;
}
td.available.active.end-date {
  background-color: var(--ion-color-primary);
  color: white;
}
td.available.active.end-date.in-range {
  background-color: var(--ion-color-primary);
  color: white;
}
td.available.in-range {
  background-color: var(--ion-color-primary-shade) !important;
  color: white;
}
td.available {
  
  color: white;
  :hover {
    background-color: rgba(240, 76, 0, 0.219) !important;
    color: white;
  }
}
td.available.off {
  background-color: var(--ion-color-secondary) !important;
  color: white;
}
.md-drppicker.drops-down-auto.ltr.shown.double {
  width: 500px;
}



ngx-daterangepicker-material .md-drppicker {
  width: 510px;
  .md-drppicker td.active, .md-drppicker td.active:hover {
    background-color: red !important;
    color: white;
  }
  background-color: black !important;
  .calendar {
    background-color: black !important;
    color: white !important;
    .calendar-table {
      background-color: black !important;
      color: white !important;
      .table-condensed{
        background-color: black !important;
        color: white !important;
        tbody {
          background-color: black !important;
          color: white !important;

        }
      }
    }
  }
}



.background-invisble {
  background-color: transparent !important;
}
@import "./theme/ion-footer-checkout.scss";
@import "./theme/ion-footer.add-to-cart.scss";
@import "./theme/ion-header-searchbar.scss";
@import "./theme/ion-loading.md.scss";
@import "./theme/ion-title.venue-name.scss";
@import "./theme/theme-1.scss";
@import "./theme/theme-2.scss";
@import "./theme/light-theme.scss";

.wide-modal { 
  --min-width: 1000px;
  --border-radius: var(--standard-border-radius);
  //scale
  transform: scale(1.2);
  --background-color: transparent !important;
  //transparent background
  --background: transparent !important;
 // ion-toolbar {
 //   --background: transparent;
 //   color: white;
 // }
 // ion-content {
 //   --background: transparent;
 // }
}

.width-modal { 
  --min-width: 700px;
  --min-height: 90vh;
  --border-radius: 20px;
  //scale

  --background-color: transparent !important;
  //transparent background
  --background: transparent !important;
 // ion-toolbar {
 //   --background: transparent;
 //   color: white;
 // }
 // ion-content {
 //   --background: transparent;
 // }
}
.large-width-modal { 
  --min-width: 900px;
  --min-height: 90vh;
  --border-radius: 20px;
  //scale

  --background-color: transparent !important;
  //transparent background
  --background: transparent !important;
 // ion-toolbar {
 //   --background: transparent;
 //   color: white;
 // }
 // ion-content {
 //   --background: transparent;
 // }
}.width-modal
.cdk-drag-dragging {
  // make bigger and transparent
  transform: scale(1.2);
  opacity: 0.5;
}

.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
  opacity: 0;
}

#live-chat-widget {
  bottom: 0px !important;
}

.firstLayer {
  background-color: var(--ion-color-secondary);
  border-radius: 14px;
  border: 1px solid var(--ion-color-secondary-tint);
  overflow: hidden;
}

.ModifierPopover {
  --height: 200px;
}

.secondLayer {
  border: 1px solid var(--ion-color-secondary-tint);
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
  border-radius: 14px;
  background-color: var(--ion-color-secondary);
  overflow: hidden;

}


ion-list-header.sc-ion-select-popover-ios {
  color: white;
}

.ngx-charts .line-highlight {
  display: block !important;

}

ion-item-group.rounded-group {
  border-radius: var(--standard-border-radius);
  background-color: var(--ion-color-secondary);
  overflow: hidden;

  ion-item {
    --border-color: var(--ion-color-secondary-tint);
  }
  //get the last child (at any level) that is a ion-item

  ion-item:last-child {
    border-color: var(--ion-color-secondary);;
  }
}



ion-segment-button {

  min-width: auto;
  color: var(--ion-color-secondary-contrast);
}

.segment-button-checked::part(indicator) {
  border: 1.5px solid var(--ion-color-secondary-tint);
  border-radius: 10px;

}



.permissionsBlock {
  background:rgba(26, 26, 26, 0.24);
  backdrop-filter: Saturate(180%) blur(40px);
 ion-content::part(background) {
  background: transparent
}

}

.popover-arrow::after {
  background: white;
}

//acces the shadow of the popover
ion-popover::part(arrow)::after {
  background: rgba(0, 0, 0, 0.5);
}



swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-text-color-step-800, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}