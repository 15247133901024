body.light-theme {
  /// ----- LIGHT THEME ----

  .splashScreen * {
    background-color: var(--ion-background-color, #fff) !important;
    transition: none;
  }

  .menu_logo.dark-theme {
    display: none !important;
  }
  .menu_logo.light-theme {
    display: block !important;
  }

  --ion-color-primary: #000000;
	--ion-color-primary-rgb: 0,0,0;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #000000;
	--ion-color-primary-tint: #1a1a1a;

	--ion-color-secondary: #ffffff;
	--ion-color-secondary-rgb: 255,255,255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #e0e0e0;
	--ion-color-secondary-tint: #ffffff;

	--ion-color-tertiary: #515151;
	--ion-color-tertiary-rgb: 81,81,81;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #474747;
	--ion-color-tertiary-tint: #626262;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 255,255,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #ffffff;


  --ion-color-text: #000000;
  --ion-color-text-rgb: 255, 255, 255;
  --ion-color-text-contrast: #000000;
  --ion-color-text-contrast-rgb: 0, 0, 0;
  --ion-color-text-shade: #e0e0e0;
  --ion-color-text-tint: #ffffff;

  --ion-color-transparent: #ffffff00;
	--ion-color-transparent-rgb: 255,255,255, 0;
	--ion-color-transparent-contrast: #ffffff;
	--ion-color-transparent-contrast-rgb: 0,0,0, 0;
	--ion-color-transparent-shade: #e0e0e000;
	--ion-color-transparent-tint: #ffffff00;

  --ion-color-background: var(--ion-color-light);

  --ion-text-color: var(--ion-color-text);
  --ion-color-contrast: var(--ion-color-dark-grey);

  

  ion-content {
    --background: var(--ion-color-background);
  }

  .alert-wrapper {
    color: rgb(0, 0, 0) !important;
    background-color: var(--ion-color-primary) !important;
    --background: var(--ion-color-background);
    --ion-text-color: black !important;
    --ion-color-primary: white !important;
    
  }
  .alert-radio-label {
    color: rgb(48, 48, 48) !important;
  }
  //if selected make bold
  [aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
    font-weight: bold;
    color: rgb(0, 0, 0) !important;
  }
  
.alert-button-inner {
  color:black;
    --color: black !important;
  }
  .ion-color-dark-grey {
    --ion-color-base: white;
    --ion-color-base-rgb: white;
    --ion-color-contrast: black;
    --ion-color-contrast-rgb: white;
    --ion-color-shade: white;
    --ion-color-tint: white;
  }

  .ion-color-white {
    --ion-color-base: #152a3f;
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
  }

  .ion-color-primary {
    --ion-color-base: var(--ion-color-primary, #3880ff) !important;
    --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
    --ion-color-contrast: white !important;
    --ion-color-contrast-rgb: var(
      --ion-color-primary-contrast-rgb,
      255,
      255,
      255
    ) !important;
    --ion-color-shade: var(--ion-color-primary-shade, #3171e0) !important;
    --ion-color-tint: var(--ion-color-primary-tint, #4c8dff) !important;
  }

  .ion-color-pink {
    --ion-color-base: #152a3f;
    --ion-color-base-rgb: var(---ion-color-pink-rgb);
    --ion-color-contrast: var(--ion-color-pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-pink-shade);
    --ion-color-tint: var(--ion-color-pink-tint);
  }
  :host {
    --background-checked: white;
    --border-color-checked: white;
  }

  .view-cart-treat-me,
  .confirmDetails,
  .placeOrder {
    --ion-color-base: grey;
  }

  ion-fab ion-icon {
    --ion-color-base: white !important;
  }

  ion-back-button {
    color: black;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  ion-header,
  ion-toolbar,
  .toolbar-container,
  .toolbar-background,
  ion-grid,
  ion-list,
  ion-item-divider,
  ion-item {
    background-color: white;
    --background: white;
    color: black;

  }


  ion-label{
    background-color: transparent !important;
    --background: transparent !important;
    color: black;
  }




  .socialLink {
    color: #898989;
    font-size: 24px;
  }

  .featured-items,
  .menu-label {
    background-color: #c7c7c7;
    padding: 18px;
    width: 102vw;
    font-weight: 700;
    font-size: 15px;
    position: relative;
    right: 10px;
  }

  ion-segment-button {
    --background: lightgrey;
  }
  ion-segment-button ion-label.ios {
    color: white !important;
  }
  ion-segment-button ion-label {
    background: none !important;
  }

  .action-sheet-group .action-sheet-selected {
    color: white;
  }
  .action-sheet-group-cancel .action-sheet-button-inner {
  color: white;
}

  .options-list ion-badge {
    border: grey solid 1px;
  }

  ion-checkbox {
    --background-checked: white;
    --border-color-checked: white;
    --border-color-checked: grey;
    --background: white;
    --border-color: grey;
  }

  .treatSteps .ant-steps-item-title {
    color: black !important;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: black;
  }

  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: black !important;
  }

  .ant-steps-item-wait
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: grey;
  }

  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: white;
    border: 2px solid black;
  }

  dq-treat-me-influencer-header ion-avatar {
    margin: auto;
    height: 200px !important;
    width: 200px !important;
    border-style: solid;
  }

  ion-avatar {
    height: 43px !important;
    width: 43px !important;
    border-style: solid;
    position: relative;
    border-style: none;
    margin-top: 4px;
  }

  ion-item-group {
    border: 1px solid black;
    margin: 3px;
  }

  ion-menu *,
  toolbar-content,
  :host(.ion-color) .toolbar-background {
    --ion-color-base: var(--ion-background-color, #fff) !important;
    background-color: var(--ion-background-color, #fff) !important;
    --background: var(--ion-background-color, #fff) !important;
    background: var(--ion-background-color, #fff) !important;
    color: white !important;
    border: none !important;
  }

  ion-searchbar {
    --ion-color-base: #c7c7c7 !important;
  }

  .mixed_label {
    color: black !important;
  }

  .orderDetailsItemSummaryOrderPlaced {
    border: none;
  }

  .continue {
    color: white;
  }
  
  
  .modal-card {
    --box-shadow: -1px -3px 20px 0px black !important;
   
  }
}



