// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

//@import "~ng-zorro-antd/style/index.min.css";
//@import "~ng-zorro-antd/steps/style/index.min.css";
//@import "~ng-zorro-antd/progress/style/index.min.css";


ion-menu {
  ion-header {
    ion-title {
      text-align: center;
      font-size: 15px;
      text-transform: uppercase;
    }
  }
  ion-content {
    ion-list {
      --background: var(--ion-color-mid-grey);
      padding: 0 !important;

      ion-item {
        --background: var(--ion-color-mid-grey);
        --background-activated: var(--ion-color-pink);
        --color: var(--ion-color-text);
        --padding-top: 4px;
        --padding-bottom: 4px;
        font-size: 15px;

        ion-icon {
          width: 24px;
          height: 24px;
          color: var(--ion-color-dark-grey);
        }
      }
    }
  }
}
ion-img.menu_logo {
  width: 120px;
  margin: 50px auto 0;
}

.treatSteps .ant-steps-item-title {
  color: white !important;
  position: relative;
  bottom: 5px;
  left: 32px;
}

.treatSteps .ant-steps-icon-dot {
  left: 0px !important;
  height: 13px !important;
  width: 13px !important;
}

.treatSteps nz-steps {
  padding-top: 18px;
  width: 90vw;

  .ant-steps-item-active {
    font-weight: 900;

    .ant-steps-item-title {
      font-size: 20px;
      position: relative;
      left: 28px;
    }
  }
  .ant-steps-item-finish {
    .ant-steps-item-title {
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-title {
      opacity: 0.6;
    }
  }
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: black;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: black !important;
}

.treatSteps
  .ant-steps-vertical.ant-steps-dot
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  left: -6px;
}

.ant-steps-vertical.ant-steps-dot
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  top: 0 px !important;
  padding: 17px 0px 0px 0px !important;
  height: 114% !important;
}

.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: white;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: black;
  border: 2px solid white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}

.action-sheet-primary {
  color: var(--ion-color-mid-pink) !important;
}
.action-sheet-secondary {
  color: var(--ion-color-mid-pink-contrast) !important;
}
.action-sheet-danger {
  color: var(--ion-color-danger) !important;
}
