body.theme-1 {
  --ion-color-dark-purple: #fb4f41;
  --ion-color-dark-purple-rgb: 251, 79, 65;
  --ion-color-dark-purple-contrast: #000000;
  --ion-color-dark-purple-contrast-rgb: 0, 0, 0;
  --ion-color-dark-purple-shade: #dd4639;
  --ion-color-dark-purple-tint: #fb6154;

  --ion-color-mid-purple: #ffffff;
  --ion-color-mid-purple-rgb: 255, 255, 255;
  --ion-color-mid-purple-contrast: #000000;
  --ion-color-mid-purple-contrast-rgb: 0, 0, 0;
  --ion-color-mid-purple-shade: #e0e0e0;
  --ion-color-mid-purple-tint: #ffffff;

  --ion-color-purple: #ffffff;
  --ion-color-purple-rgb: 255, 255, 255;
  --ion-color-purple-contrast: #000000;
  --ion-color-purple-contrast-rgb: 0, 0, 0;
  --ion-color-purple-shade: #e0e0e0;
  --ion-color-purple-tint: #ffffff;

  --ion-color-pink: #fb4f41;
  --ion-color-pink-rgb: 251, 79, 65;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 0, 0, 0;
  --ion-color-pink-shade: #dd4639;
  --ion-color-pink-tint: #fb6154;

  --ion-color-dark-grey: #152a3f;
  --ion-color-dark-grey-rgb: 21, 42, 63;
  --ion-color-dark-grey-contrast: #ffffff;
  --ion-color-dark-grey-contrast-rgb: 255, 255, 255;
  --ion-color-dark-grey-shade: #122537;
  --ion-color-dark-grey-tint: #2c3f52;

  --ion-color-mid-grey: #1f344b;
  --ion-color-mid-grey-rgb: 31, 52, 75;
  --ion-color-mid-grey-contrast: #ffffff;
  --ion-color-mid-grey-contrast-rgb: 255, 255, 255;
  --ion-color-mid-grey-shade: #1b2e42;
  --ion-color-mid-grey-tint: #35485d;

  --ion-color-light-grey: #1592e6;
  --ion-color-light-grey-rgb: 21, 146, 230;
  --ion-color-light-grey-contrast: #ffffff;
  --ion-color-light-grey-contrast-rgb: 255, 255, 255;
  --ion-color-light-grey-shade: #1280ca;
  --ion-color-light-grey-tint: #2c9de9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-text: #ffffff;
  --ion-color-text-rgb: 255, 255, 255;
  --ion-color-text-contrast: #000000;
  --ion-color-text-contrast-rgb: 0, 0, 0;
  --ion-color-text-shade: #e0e0e0;
  --ion-color-text-tint: #ffffff;

  --ion-color-primary: var(--ion-color-purple);
  --ion-color-primary-rgb: var(--ion-color-purple-rgb);
  --ion-color-primary-contrast: var(--ion-color-purple-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-purple-shade);
  --ion-color-primary-tint: var(--ion-color-purple-tint);

  --ion-text-color: var(--ion-color-text);
  --ion-color-contrast: var(--ion-color-dark-grey);

  .alert-wrapper {
    --ion-text-color: var(---ion-color-text);
    --ion-color-primary: var(--ion-color-dark-purple);
  }
}
