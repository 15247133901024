ion-footer.checkout {
  ion-toolbar {
  

    & + ion-toolbar {
    }

    ion-button {
     
      
     
     
    }

    ion-item.total_line {
      --background: transparent;
      color: var(--ion-color-text);
      font-weight: bold;
      text-transform: uppercase;

      ion-label.total_price {
        font-size: 25px;
        text-align: right;
      }
    }
  }
}
